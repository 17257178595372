<template>
  <div class="teacher-start-page flex justify-content-center align-items-center">
    <div class="w-12 xl:w-6 text-center py-8">
      <div class="mb-6">
        <img :src="logo" class="w-6 xl:w-5 h-auto" alt="Logo" />
      </div>

      <BaseParagraph>
        {{ $t("stp.teacher.startpage.description1") }}
      </BaseParagraph>

      <BaseParagraph>
        {{ $t("stp.teacher.startpage.description2") }}
      </BaseParagraph>

      <BaseButton
        @click="goToTeacherRegister"
        label="stp.teacher.startpage.btn.label.register"
        class="mt-3"
        small
      />
    </div>
  </div>
</template>

<script>
import logo from "@/assets/img/logo.svg";
import { mapGetters } from "vuex";
import useApi from "@use/useApi";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "StartPage",

  setup() {
    const store = useStore();
    const router = useRouter();

    const user = store.getters["usm/authUser/user"];
    const authUserReady = ref(!!user);

    const isAuthenticated = store.getters["usm/auth/isAuthenticated"];

    if (!authUserReady.value) {
      const { docs } = useApi({
        module: "usm",
        method: "showAuthUser",
        onMount: !authUserReady.value && isAuthenticated,
        onSuccess: async () => {
          await store.dispatch("usm/authUser/saveData", docs.value);
          authUserReady.value = true;
        },
      });
    }

    const goToTeacherRegister = async () => {
      try {
        await router.push({ name: "TeacherRegister" });
      } catch {
        return;
      }
    };

    return { authUserReady, goToTeacherRegister };
  },

  computed: {
    ...mapGetters("usm/auth", ["isAuthenticated"]),

    logo() {
      return logo;
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher-start-page {
  min-height: 70vh;
}
</style>
